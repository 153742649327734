import React, { useState, useEffect, useCallback } from 'react';
import { Volume2, Check, X, ArrowRight, RotateCcw } from 'lucide-react';
import styled from 'styled-components';
import useSound from 'use-sound';
import typingSound from '../../../data/audio_effect/keyboard-typing-one-character.mp3';

const ExerciseContainer = styled.div`
  background-color: ${props => props.theme.backgroundColor || '#ffffff'};
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${props => props.theme.titleColor || '#2d3748'};
  margin-bottom: 1rem;
`;

const ProgressText = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.textColor || '#4a5568'};
  margin-bottom: 0.5rem;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${props => props.theme.progressBarBackgroundColor || '#e2e8f0'};
  border-radius: 2px;
  margin-bottom: 1.5rem;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: ${props => props.theme.progressBarFillColor || '#3B82F6'};
  transition: width 0.3s ease;
`;

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
`;

const AnimatedInput = styled.input`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 2rem;
  color: ${props => props.theme.inputTextColor || '#2d3748'};
  width: 100%;
  padding: 0.75rem 0;
  border: none;
  border-bottom: 2px solid ${props => props.theme.inputBorderColor || '#e2e8f0'};
  background: transparent;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.inputFocusBorderColor || '#3B82F6'};
  }
`;

const ActionButton = styled.button`
  background-color: ${props => props.theme.buttonBackgroundColor || '#3B82F6'};
  color: ${props => props.theme.buttonTextColor || 'white'};
  padding: 0.75rem;
  border-radius: 50%;
  font-size: 1.25rem;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;

  &:hover {
    background-color: ${props => props.theme.buttonHoverBackgroundColor || '#2563EB'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${props => props.quit && `
    background-color: ${props.theme.quitButtonBackgroundColor || '#EF4444'};

    &:hover {
      background-color: ${props.theme.quitButtonHoverBackgroundColor || '#DC2626'};
    }
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

const FeedbackMessage = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  ${props => props.correct ? `color: ${props.theme.correctColor || '#10B981'};` : `color: ${props.theme.incorrectColor || '#EF4444'};`}
`;

const WordDisplay = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme.textColor || '#4a5568'};
  margin-bottom: 0.5rem;
`;

const Translation = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.hintColor || '#6B7280'};
  font-style: italic;
  margin-bottom: 1rem;
`;

const DiffContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: monospace;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const DiffColumn = styled.div`
  flex: 1;
  padding: 0.5rem;
  border-radius: 4px;
`;

const UserInput = styled(DiffColumn)`
  background-color: ${props => props.theme.incorrectBackgroundColor || '#ffebe9'};
  color: ${props => props.theme.incorrectColor || '#EF4444'};
  margin-right: 0.5rem;
`;

const CorrectWord = styled(DiffColumn)`
  background-color: ${props => props.theme.correctBackgroundColor || '#e6ffec'};
  color: ${props => props.theme.correctColor || '#10B981'};
  margin-left: 0.5rem;
`;

const CompletionMessage = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const CompletionTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${props => props.theme.congratulationsColor || '#10B981'};
  margin-bottom: 1rem;
`;

const CompletionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
`;

const CompletionButton = styled(ActionButton)`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const RetryButton = styled(CompletionButton)`
  background-color: ${props => props.theme.buttonBackgroundColor || '#3B82F6'};
  color: ${props => props.theme.buttonTextColor || 'white'};

  &:hover {
    background-color: ${props => props.theme.buttonHoverBackgroundColor || '#2563EB'};
  }
`;

const QuitButton = styled(CompletionButton)`
  background-color: ${props => props.theme.quitButtonBackgroundColor || '#EF4444'};
  color: ${props => props.theme.quitButtonTextColor || 'white'};

  &:hover {
    background-color: ${props => props.theme.quitButtonHoverBackgroundColor || '#DC2626'};
  }
`;

const DictationExercise = ({ words, noteId, pageId, onClose, theme = {} }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);
  const [progress, setProgress] = useState(0);
  const [playTypingSound] = useSound(typingSound, { volume: 0.5 });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const currentWord = words[currentWordIndex];

  const playAudio = useCallback(() => {
    if (currentWord && currentWord.audioUrl) {
      const audio = new Audio(currentWord.audioUrl);
      audio.play();
    }
  }, [currentWord]);

  useEffect(() => {
    if (words.length > 0) {
      setCurrentWordIndex(0);
    }
  }, [words]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else {
      playAudio();
    }
  }, [currentWordIndex, playAudio, isInitialRender]);

  const checkAnswer = useCallback(() => {
    const isCorrect = userInput.toLowerCase().trim() === currentWord.word.toLowerCase();
    setIsCorrect(isCorrect);
    setFeedback(isCorrect ? 'Correct! Press Enter for next word.' : 'Incorrect. Try again.');
    if (isCorrect) {
      setProgress(((currentWordIndex + 1) / words.length) * 100);
    }
  }, [userInput, currentWord, currentWordIndex, words.length]);

  const nextWord = useCallback(() => {
    if (currentWordIndex + 1 < words.length) {
      setCurrentWordIndex((prevIndex) => prevIndex + 1);
      resetState();
    } else {
      setIsCompleted(true);
    }
  }, [words.length, currentWordIndex]);

  const handleRetry = useCallback(() => {
    setCurrentWordIndex(0);
    setProgress(0);
    setIsCompleted(false);
    resetState();
  }, []);

  const resetState = useCallback(() => {
    setUserInput('');
    setFeedback('');
    setIsCorrect(null);
  }, []);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      if (isCorrect === null) {
        checkAnswer();
      } else if (isCorrect) {
        nextWord();
      }
    }
  }, [isCorrect, checkAnswer, nextWord]);

  const handleInputChange = useCallback((e) => {
    setUserInput(e.target.value.toLowerCase());
    setIsCorrect(null);
    setFeedback('');
    playTypingSound();
  }, [playTypingSound]);

  const handleGlobalKeyPress = useCallback((e) => {
    if (e.ctrlKey && e.key === 'e') {
      playAudio();
    }
  }, [playAudio]);

  useEffect(() => {
    playAudio();
  }, [currentWordIndex, playAudio]);

  useEffect(() => {
    window.addEventListener('keydown', handleGlobalKeyPress);
    return () => {
      window.removeEventListener('keydown', handleGlobalKeyPress);
    };
  }, [handleGlobalKeyPress]);

  return (
    <ExerciseContainer theme={theme}>
      <Title theme={theme}>Dictation Exercise</Title>
      {!isCompleted ? (
        <>
          <ProgressText theme={theme}>Word {currentWordIndex + 1} of {words.length}</ProgressText>
          <ProgressBar theme={theme}>
            <ProgressFill progress={progress} theme={theme} />
          </ProgressBar>
          <ButtonGroup>
            <ActionButton onClick={playAudio} theme={theme}>
              <Volume2 size={20} />
            </ActionButton>
            <ActionButton onClick={onClose} theme={theme} quit>
              <X size={20} />
            </ActionButton>
          </ButtonGroup>
          <InputContainer>
            <AnimatedInput
              type="text"
              value={userInput}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              theme={theme}
            />
          </InputContainer>
          {feedback && (
            <FeedbackContainer>
              <FeedbackMessage correct={isCorrect} theme={theme}>
                {isCorrect ? <Check className="inline mr-2" /> : <X className="inline mr-2" />}
                {feedback}
              </FeedbackMessage>
              {!isCorrect && (
                <DiffContainer>
                  <UserInput theme={theme}>{userInput}</UserInput>
                  <CorrectWord theme={theme}>{currentWord.word}</CorrectWord>
                </DiffContainer>
              )}
              <WordDisplay theme={theme}>{currentWord.word}</WordDisplay>
              <Translation theme={theme}>{currentWord.translation}</Translation>
            </FeedbackContainer>
          )}
          {isCorrect && (
            <ActionButton onClick={nextWord} theme={theme}>
              <ArrowRight size={20} />
            </ActionButton>
          )}
        </>
      ) : (
        <CompletionMessage>
          <CompletionTitle theme={theme}>Congratulations!</CompletionTitle>
          <ProgressText theme={theme}>
            You've completed the dictation exercise.
          </ProgressText>
          <CompletionButtons>
            <ActionButton onClick={handleRetry} theme={theme}>
              <RotateCcw size={20} />
            </ActionButton>
            <ActionButton onClick={onClose} theme={theme} quit>
              <X size={20} />
            </ActionButton>
          </CompletionButtons>
        </CompletionMessage>
      )}
    </ExerciseContainer>
  );
};

export default DictationExercise;